<style scoped>
@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1320px;
  }
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
  color: #000;
  background-color: #fcb913;
  border-color: #fcb913;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
  outline: none;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #000;
  background-color: #fcb913;
  border-color: #fcb913;
}

.rank-tbl .table-outer {
  border: 0px solid #e1e1e1;
  padding: 0px;
  overflow-y: scroll !important;
}

.customDrodown {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.customDrodown select {
  font-family: "Inter" !important;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  width: 204px;
  height: 54px;
  appearance: none;
  padding: 15px;
  outline: 0;
  background: #f9fbfc;
  width: 100%;

  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill= 'black' height= '24' viewBox= '0 0 24 24' width= '24' xmlns= 'http://www.w3.org/2000/svg' ><path d= 'M7 10l5 5 5-5z' /><path d= 'M0 0h24v24H0z' fill= 'none' /></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 14px;
}

.customBreadcrums h3 {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #282828;
  margin-bottom: 0px;
  text-transform: uppercase;
}

.customBreadcrumsRightSide p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  display: flex;
  align-items: flex-end;
  color: #1c1c1c;
  margin-bottom: 0px;
}

.customBreadcrums {
  margin-bottom: 40px;
}

.customBreadcrumsRightSide p:nth-child(1) {
  margin-right: 18px;
}

.customBreadcrumsRightSide p span {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  display: flex;
  align-items: flex-end;
  color: #1c1c1c;
  margin-right: 10px;
}

.filterBottom ul.subListTabs {
  display: flex;
  margin-top: 4px;
  flex-wrap: wrap;
}

.filterBottom ul.subListTabs li.filter-active.active {
  background: #fcb913;
}

.filterBottom ul.subListTabs li {
  width: auto;
  height: 40px;
  background: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 6px;
  border: 1px solid #e9e9e9;
  margin: 2px;
}

.filterBottom ul.subListTabs li a {
  text-decoration: none;
}

.filterBottom ul.subListTabs li a {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  letter-spacing: 0.011em;
  color: #000000;
}

.customBreadcrums {
  display: flex;
  justify-content: space-between;
}

.customBreadcrumsRightSide {
  display: flex;
}

.list-indivi ul {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap !important;
  align-items: center;
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  align-items: center;
  text-align: center;
  color: #000000;

}

.list-indivi ul li {

  cursor: pointer;
}

.list-indivi ul li a {
  display: inline-block;
  color: #000;
}

.list-indivi ul li a.tab-a:hover,
.list-indivi ul li:hover {
  background-color: #fcb913;
  text-decoration: none;
}

.list-indivi ul li.active {
  background-color: #fcb913;
  border: 1px solid #fcb913;
}

.subListTabs ul li.active {
  background-color: #fcb913;
}

.list-indivi ul li img {
  vertical-align: middle;
}

.list-indivi ul li span {
  vertical-align: middle;
}

.individual {
  padding: 40px 0 0 0;
}

.individual .div-flex {
  justify-content: space-between;
}

.ranking-select button {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;

  align-items: center;

  color: #000000;
  background: #f9fbfc;
  border: 1px solid #e1e1e1;

  cursor: pointer;
}

.ranking-select button.result-type-btn-active {
  background-color: #fcb913;
  border: 1px solid #fcb913;
}

.div-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.div-flex .col-left {
  width: 50%;
  height: auto;
  margin: auto;
  z-index: 1;
}

.div-flex .col-right {
  width: 50%;
  height: auto;
  margin: auto;
}

.individual ul {
  padding: 0;
  margin: 0;
}

.individual ul li {
  padding: 4px 4px;
  list-style-type: none;
  border: 1px solid #e1e1e1;
}

.individual ul li a.tab-a img {
  margin-right: 10px;
}


.section-main {
  padding: 52px 20px 70px 20px;
}

.section-main.blue-bg {
  background-color: #000000;
  display: flex;
  align-items: center;
}



.section-main h1 {
  text-align: center;
  font-family: "Thunder" !important;
  font-style: normal;
  font-weight: 800;
  font-size: 60px;
  line-height: 60px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;

}


.section-main select.sel-cutom {
  background: #ffffff;
  border: 1.4px solid transparent;
  width: 50%;
  padding: 16px 20px;
  margin: 8px 5px;
  box-sizing: border-box;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #00b4e3;
  color: #fff;
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 10px;
  float: left;
  font-family: Poppins;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
}

.section-main select.sel-cutom strong {
  font-weight: 900;
}

.section-main .div-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-main .div-flex .col-left {
  width: 50%;
  height: auto;
  margin: auto;
  z-index: 1;
}

.section-main .div-flex .col-right {
  width: 50%;
  height: auto;
  margin: auto;
}


.load-btn-wrpr {

  text-align: center;
  margin: 10px auto;
}

.loadmore {
  border: 1px solid #e1e1e1;
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: inline-block;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0125em;
  color: #000000;
  background-color: #fff;
  margin: 0 6px;
  padding: 12px 40px;
  cursor: pointer;
  min-width: 144px;

}

.loadmore:hover {
  background: #fcb913;
  color: #000000 !important;
}

.rank-tbl tr td:nth-child(3) {
  padding-left: 0 !important;
}

.rank-tbl tr td:nth-child(2) {
  padding-left: 0 !important;
}

.rank-tbl tr td:nth-child(2) p {
  margin-bottom: 0;
  margin-top: 10px;
}

@media (max-width: 1024px) {
  .btn-group {
    width: 100% !important;
  }

  .customDrodown {
    justify-content: left;
  }

  .ranking-select {
    text-align: center;
    margin: 20px;
  }

  .wrpr-btns-mob {
    background-color: #f6f6f6;
  }

  .wrpr-btns-mob button {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 37px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.046em;
    color: #000000;
    display: inline-block;
    border: none;
    background-color: #f6f6f6;
    padding: 5px;
  }

  .wrpr-btns-mob select {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 37px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.046em;
    color: #000000;
    width: 33.3%;
    display: inline-block;
    border: none;
    padding: 10px;
    color: #212529;
    background-color: #f6f6f6;
    border: 0px solid #ced4da;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .wrpr-btns-mob button.active {
    background: #fcb913;
  }

  .buttons-group-custom {
    width: 33.3% !important;
  }

  .section-main h1 {
    font-family: "Barlow Condensed", sans-serif !important;
  }
}


@media (max-width: 575px) {
  .section-main.blue-bg {
    height: auto;
  }

  .team-name-mob {
    display: flex !important;
    justify-content: space-evenly;
    align-items: center;
  }

  .rank-tbl-mob>div {
    padding: 0 !important;
  }

  .rank-tbl .container {
    padding: 0;
  }

  .rank-tbl-mob .container {
    padding: 0;
  }

  .individual {
    display: none;
  }

  .section-main .div-flex .col-left {
    width: 59%;
  }

  .rank-tbl table tr td {
    padding: 0px 0px;
  }

  .customBreadcrums h3 {
    font-size: 18px !important;
  }

  .section-main h1 {
    margin: 20px 0px;
    font-size: 35px;
    line-height: 36px;
  }

  .customBreadcrums {
    margin-bottom: 0px;
  }

  .individual {
    padding: 20px 0 0 0;
  }

  .rank-tbl .table-outer {
    overflow-y: scroll !important;
  }

  .individual .div-flex {
    justify-content: space-between;
    flex-direction: column;
  }

  .list-indivi {
    width: 100%;
    margin: 7px;
  }

  .individual ul li {
    width: 100%;
  }

  .filterBottom ul.subListTabs {
    justify-content: center;
  }


}

@media (max-width: 400px) {
  .ranking-select {
    margin: 25px 0px;
  }

  .customDrodown select {
    font-size: 13px;
  }

  .ranking-select button {
    font-size: 13px;
  }

  .section-main h1 {
    margin: 14px 0px;
    font-size: 30px;
    line-height: 29px;
  }
}
</style>

<template>
  <div
    class="page-template page-template-page-how-it-works page-template-page-how-it-works-php page page-id-22 page-how-it-works home"
    data-aos-easing="ease-in"
    data-aos-duration="600"
    data-aos-delay="0"
  >
    <div class="dashed-line dashed-line-1"></div>
    <div class="dashed-line dashed-line-2"></div>
    <div class="dashed-line dashed-line-3"></div>
    <div class="dashed-line dashed-line-4"></div>
    <div class="dashed-line dashed-line-5"></div>

    <div class="homepage-wrap clearfix">
      <!--routes section start-->

      <!--start advance sections -->
      <div class="section-main blue-bg pattern-bg">
        <div class="container">
          <h1 v-if="isStages && isSeriesEvent">
            {{ event?.name }} | Stage {{ parseInt(currentStage) + 1 }} of
            {{ stages.length }} -
            <span style="color: #00b2e3">
              {{ mapUserGender(event?.gender) }}</span
            >
          </h1>
          <h1 v-else>
            {{ event?.name }} -
            <span style="color: #00b2e3">{{
              mapUserGender(event?.gender)
            }}</span>
          </h1>

          <div v-if="isMobileDevice">
            <div :class="isSeriesEvent ? 'col-left' : 'col-left'">
              <div class="customDrodown">
                <select @change="applyFilters(parseInt($event.target.value))">
                  <option value="0">Select Category</option>
                  <option
                    v-for="item in eventCategories"
                    :key="item.CategoryId"
                    :value="item.CategoryId"
                  >
                    {{ item.Label }}
                  </option>
                </select>
              </div>
            </div>
            <br v-show="isSeriesEvent" />
            <div class="col-right" v-show="isSeriesEvent">
              <div class="customDrodown">
                <select v-model="currentStage" @change="updateStage">
                  <option
                    :value="k"
                    v-for="(stage, k) in stages"
                    :key="stage.DayId"
                  >
                    <strong>{{ stage.DayName }}:</strong>
                    {{ stage.StartDate }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div v-else class="div-flex">
            <div class="col-left">
              <div class="customDrodown">
                <select @change="applyFilters(parseInt($event.target.value))">
                  <option value="0">Select Category</option>
                  <option
                    v-for="item in eventCategories"
                    :key="item.CategoryId"
                    :value="item.CategoryId"
                  >
                    {{ item.Label }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-right" v-show="isSeriesEvent">
              <div class="customDrodown">
                <select v-model="currentStage" @change="updateStage">
                  <option
                    :value="k"
                    v-for="(stage, k) in stages"
                    :key="stage.DayId"
                  >
                    <strong>{{ stage.DayName }}:</strong>
                    {{ stage.StartDate }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="individual mb-10" v-if="!isMobileDevice">
        <div class="container">
          <div class="customBreadcrums">
            <h3>
              Results / {{ event?.name }} - {{ mapUserGender(event?.gender) }} /
              {{ getRouteName(event, selectedDayId) }}
            </h3>
            <div class="customBreadcrumsRightSide">
              <p>
                <span>Distance:</span>
                <b>{{ getDistanceAndElevation(event, selectedDayId) }}KM</b>
              </p>
              <p>
                <span>Elevation:</span>
                <b
                  >{{ getDistanceAndElevation(event, selectedDayId, true) }}M</b
                >
              </p>
            </div>
          </div>

          <!--start live graph render section -->
          <LiveEventCanvas
            v-if="!isSeriesEvent && selectedDayId && isLive"
            :selectedDayId="selectedDayId"
            :spectatorPlayer="spectatorPlayer"
            :test="false"
          />
          <!--end live graph render section -->

          <div class="container-fluid">
            <div class="row">
              <div class="col-9 p-0 list-indivi">
                <ul>
                  <li
                    class="filter-active"
                    :class="activeItem.id === item.id &&
                      activeItem.lapNo === item.lapNo
                      ? 'active'
                      : ''
                      "
                    v-for="item in getLeaderboardsData"
                    :key="item.id"
                  >
                    <a
                      class="tab-a p-3"
                      data-id="tab1"
                      @click="filterResult(item.type, item.id, item.lapNo)"
                      >{{ item.label }}</a
                    >
                  </li>
                </ul>
              </div>

              <div
                class="col-3 p-0 ranking-select text-right"
                v-show="isSeriesEvent"
              >
                <button
                  class="result-type p-3"
                  :class="!isStages && 'result-type-btn-active'"
                  @click.prevent="toggleStages(false)"
                >
                  General Ranking
                </button>
                <button
                  class="result-type p-3"
                  :class="isStages && 'result-type-btn-active'"
                  @click.prevent="toggleStages(true)"
                >
                  Stage Ranking
                </button>
              </div>
            </div>
          </div>

          <div class="filterBottom" v-if="isStages">
            <ul class="subListTabs">
              <li
                class="filter-active"
                :class="activeItem.id === item.id && activeItem.lapNo === item.lapNo
                  ? 'active'
                  : ''
                  "
                v-for="item in currentStageGates"
                :key="item.id"
              >
                <a
                  class="tab-a"
                  data-id="subTab1"
                  @click="filterResult(item.type, item.id, item.lapNo)"
                >
                  {{ item.label }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="container">
          <div class="ranking-select" v-show="isSeriesEvent">
            <button
              class="result-type p-3"
              :class="!isStages && 'result-type-btn-active'"
              @click.prevent="toggleStages(false)"
            >
              General Ranking
            </button>
            <button
              class="result-type p-3"
              :class="isStages && 'result-type-btn-active'"
              @click.prevent="toggleStages(true)"
            >
              Stage Ranking
            </button>
          </div>
        </div>
      </div>
      <!--end advance sections -->

      <!--rank and point table start-->
      <div class="container-fluid p-0 mob-btns" v-if="isMobileDevice">
        <div class="wrpr-btns-mob">
          <div
            class="btn-group wrpr-btns-mob"
            role="group"
            aria-label="btn groups"
          >
            <button
              :class="activeItem.id === item.id && activeItem.lapNo === item.lapNo
                ? 'active'
                : ''
                "
              class="buttons-group-custom"
              v-for="item in filterItems"
              :key="item.id + item.lapNo"
              @click="filterResult(item.type, item.id, item.lapNo)"
            >
              {{ item.label }}
            </button>

            <div class="btn-group buttons-group-custom" role="group">
              <button
                :class="!filterItems.find((x) => x.id === activeItem.id)
                  ? 'active'
                  : ''
                  "
                id="btnGroupDrop1"
                type="button"
                class="btn btn-secondary dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Other
              </button>
              <div
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="btnGroupDrop1"
              >
                <a
                  :class="activeItem.id === item.id && activeItem.lapNo === item.lapNo
                    ? 'active'
                    : ''
                    "
                  class="dropdown-item"
                  href="javascript:void(0)"
                  v-for="item in (isStages
                    ? [...items, ...currentStageGates]
                    : items
                  ).filter((x) => x.id !== 'individual' || x.id !== 'teams')"
                  :key="item.id"
                  @click="filterResult(item.type, item.id, item.lapNo)"
                >
                  {{ item.label }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <data-table
        :key="reloadTable"
        :loader="loading"
        v-if="!showTeamsData"
        :activeItem="activeItem"
        :isSeriesEvent="isSeriesEvent"
        :isWatt="isWatt"
        :result="result"
        :totalEventPlayers="totalEventPlayers"
        :event="event"
        :isLive="isLive"
        :selectedColumns="selectedColumns"
        :noResultsDataMessage="noResultsDataMessage"
        @spectator-mode="applySpectatorMode"
        :isMobileDevice="isMobileDevice"
        :isHandiCapEvent="isEventHandiCap"
        :isEleminationRace="isEleminationRaceBoolean"
        :eliEventPlayersInfo="eliEventPlayersInfo"
        :prizeMoneyList="prizeMoneyList"
      />

      <TeamsResultTableComponent
        :key="reloadTable"
        v-if="showTeamsData"
        :showColumns="columnsShowTeamsFrontend"
        :eventId="event.serverEventId"
        :datetime="event.datetime_epoc"
        :isMobileDevice="isMobileDevice"
        :stage="selectedDayId"
        :loader="loading"
        :isSeriesEvent="isSeriesEvent"
        :isStages="isStages"
        :noResultsDataMessage="noResultsDataMessage"
        :isLive="isLive"
        :activeItem="activeItem"
        :isHandiCapEvent="isEventHandiCap"
        :eventResultStatus="getEventResultStatus"
        :prizeMoneyList="prizeMoneyList"
      />
      <!--rank and point table end-->

      <!--load btn start-->
      <div class="load-btn-wrpr">
        <router-link :to="{ name: 'EventResultListPage' }" class="loadmore"
          >GO BACK
        </router-link>
      </div>
      <!--load btn end-->
    </div>

    <div class="progress-bar-wrap">
      <div id="progress-bar" style="--scroll: 0%"></div>
    </div>
    <div class="clearfix"></div>
  </div>
</template>
<style
  lang="scss"
  scoped
  src="../../theme/styles/pages/user/event-results/event-details.scss"
></style>

<script>
import "@/theme/style/splide-default.min.css";
import "vue3-carousel/dist/carousel.css";

import {
  columnsShowTeamsFrontend,
  teamColumns
} from "@/shared/events/columns.dto.ts";
import TeamsResultTableComponent from "@/theme/components/tables/TeamsResult";
import DataTable from "@/views/components/DataTable.vue";
// import eventCategories from "@/theme/local-db/event-categories.json";
import { mapActions, mapGetters } from "vuex";
import LiveEventCanvas from "../../theme/components/canvas/LiveEventCanvas.vue";

import {
  getDistanceAndElevation,
  getRouteName, isHandiCapEvent, IsSeriesPointAndTimeBasedEvents,
  IsSinglePointAndTimeBasedEvents,
  IsSinglePointBasedEvents,
  mapUserGender,
  isEleminationRace,
  isChampionShipEvent,
  getPrizeDistributionList
} from "@/shared/events/event.helpers.ts";

export default {
  name: "EventResult",
  props: ["eventData", "isUCIEvent"],
  components: {
    DataTable,
    TeamsResultTableComponent,
    LiveEventCanvas,
  },
  data() {
    return {
      mapUserGender: mapUserGender,
      getRouteName: getRouteName,
      getDistanceAndElevation: getDistanceAndElevation,
      noResultsDataMessage: "Loading Data...",
      eventCategories: this.eventData?.categories,
      isMobileDevice: false,
      reloadTable: 1,
      stage: 1,
      isArrowFilter: false,
      client: {},
      isLive: 0,
      selectedColumns: [],
      selectedColumnsCopy: [],
      teamColumns: teamColumns,
      columnsShowTeamsFrontend: columnsShowTeamsFrontend,
      loading: false,
      result: [],
      preStagesData: [],
      showTeamsData: false,
      liveColor: "red",
      resultBackup: [],
      isWatt: false,
      gates: [],
      topic: "",
      refresh: 0,
      lastResultCount: 0,
      items: [],
      activeItemDefault: {
        id: "individual",
        order: 0,
        type: "individual",
        lapNo: 1,
        gateType: 0,
      },
      activeItem: {},
      filterItems: [
        {
          id: "individual",
          order: 0,
          label: "Individual",
          type: "individual",
          icon: "jersey-green.png",
          lapNo: 1,
          gateType: 0,
        },
      ],
      teamsLeaderboards: [
        {
          id: "teams",
          order: 2,
          label: "Teams",
          type: "teams",
          icon: "jersey-green.png",
          lapNo: 1,
          gateType: 0,
        },
      ],
      ageLeaderboards: [],
      //   ageLeaderboards: [
      //     {
      //       id: "master",
      //       order: 3,
      //       label: "Master Winner",
      //       type: "master",
      //       icon: "jersey-green.png",
      //       lapNo: 1,
      //       gateType: 0,
      //     },
      //     {
      //       id: "young",
      //       order: 4,
      //       label: "Youth Winner",
      //       type: "young",
      //       icon: "jersey-green.png",
      //       lapNo: 1,
      //       gateType: 0,
      //     },
      //   ],
      activeFilters: {},
      overAllGates: [],
      currentStageGates: [],
      currentStage: 0,
      selectedDayId: "",
      stages: [],
      isStages: false,
      isSeriesEvent: false,
      rewardJerseys: [],
      spectatorPlayer: null,
      event: this.eventData,
      totalEventPlayers: 0,
      eliminatedPlayers: null

    };
  },
  computed: {
    ...mapGetters("event", ["gateList", "eliEventPlayersInfo"]),

    getCategories() {
      return [
        ...new Map(this.resultBackup.map((item) => [item["categoryId"], item])).values()].sort((x, y) => x.categoryId - y.categoryId);
    },
    getLeaderboardsData() {
      if (IsSinglePointBasedEvents(this.event)) {
        return [...this.items, ...this.filterItems];
      }
      return [...this.filterItems, ...this.items];
    },
    isOverallPointsGate() {
      return IsSinglePointBasedEvents(this.event) && this.overAllGates ? this.overAllGates[0] : null;
    },
    isEventHandiCap() {
      return isHandiCapEvent(this.event)
    },
    getEventResultStatus() {
      return this.event.result_status
    },
    isEleminationRaceBoolean() {
      return isEleminationRace(this.event)
    },
    prizeMoneyList() {
      return getPrizeDistributionList(this.gateList, this.selectedDayId, this.event?.winners || [], this.activeItem)
    }
  },
  watch: {
    // gateList(gates) {
    //   if (gates?.overallGates?.length) {
    //     this.addGates(gates)
    //   }
    // }
  },
  async mounted() {

    this.isMobileDevice = window.outerWidth <= 1024;
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });


    const { isLive } = this.$route.query;
    this.isLive = isLive ? (isLive === "1" || isLive === "true" ? 1 : 0) : 0;

    if (this.isEleminationRaceBoolean) {
      await this.getEliminationEventPlayersInfo(this.event.serverEventId)
    }

    await this.getSelectedColumns();
    await this.getGateList({ eventId: this.event.serverEventId });
    await this.addGates(this.gateList)
    await this.loadResult();




    if (this.isLive) {
      setInterval(async () => {
        this.filterResult(
          this.activeItem.type,
          this.activeItem.id,
          this.activeItem.lapNo,
          false
        );
      }, 5000);
    }

  },
  methods: {
    ...mapActions("event", ["getEventResult", "getGateList", "getEliminationEventPlayersInfo"]),

    onResize() {


      this.isMobileDevice = window.outerWidth <= 1024 ? true : false;


    },

    applySpectatorMode(player) {
      // player['userId'] = 'user-9' //for testing
      this.spectatorPlayer = player;
    },

    getImgURLfromStr(url) {
      return url.slice(url.indexOf("src")).split('"')[1];
    },
    getDate(dateTime) {
      const dt = new Date(dateTime.replace(" ", "T"));
      return dt.toDateString();
    },
    getStageDate() {
      return new Date(
        this.stages[this.currentStage]?.StartTime * 1000
      ).toDateString();
    },
    async applyFilters(categoryId = 0) {
      this.activeFilters =
        categoryId !== 0
          ? { ...this.activeFilters, ...{ categoryId: categoryId } }
          : {};
      await this.filterResult(
        this.activeItem.type,
        this.activeItem.id,
        this.activeItem.lapNo
      );
    },
    async setFilters() {
      const { dayId } = this.$route.query;
      if (!this.selectedDayId && dayId) {
        this.isStages = true;
        const currentDay = this.stages.find((d) => d.DayId === dayId);
        this.currentStage = this.stages.indexOf(currentDay);
      }
      this.selectedDayId = this.stages[this.currentStage]?.DayId || "";

      await this.setCurrentStageGates();

      this.items = [
        ...(IsSeriesPointAndTimeBasedEvents(this.event) && this.isSeriesEvent && !this.isStages && isChampionShipEvent(this.event.serverEventId) ? this.ageLeaderboards : []),
        ...(this.isSeriesEvent && this.isStages ? (IsSeriesPointAndTimeBasedEvents(this.event) ? this.overAllGates : []) : (!this.isSeriesEvent ? [] : this.overAllGates)),
        ...((IsSinglePointAndTimeBasedEvents(this.event) || IsSinglePointBasedEvents(this.event)) && !this.isSeriesEvent ? this.overAllGates : []),
      ];
    },

    filterIdex(og = false) {
      return og ? this.filterItems.length : this.filterItems.length + this.totalOverGates;
    },

    async updateStage(e) {
      let dataUpdate = false;

      if (e === "prev" || e === "next") {
        if (e === "prev" && parseInt(this.currentStage) !== 0) {
          this.currentStage = parseInt(this.currentStage) - 1;
          dataUpdate = true;
        }
        if (
          e === "next" &&
          parseInt(this.currentStage) < this.stages.length - 1
        ) {
          this.currentStage = parseInt(this.currentStage) + 1;
          dataUpdate = true;
        }
      } else if (e.target.value) {
        this.currentStage = e.target.value;
        dataUpdate = true;
      }

      if (dataUpdate === true) {
        this.isStages = true;
        await this.setFilters();
        await this.filterResult(
          this.isOverallPointsGate?.type || "individual",
          this.isOverallPointsGate?.id || "individual"
        );
        this.refresh++;
      }
      this.reloadTable++;
    },
    async toggleStages(isTrue) {
      this.isStages = isTrue;
      if (!isTrue) {
        this.currentStage = 0;
      }
      await this.setFilters();
      await this.filterResult(
        this.isOverallPointsGate?.type || "individual",
        this.isOverallPointsGate?.id || "individual"
      );
    },
    async onFilterArrow(splide) {
      if (this.isArrowFilter && splide._o.perPage === 1) {
        const filter = this.items[splide._i] || {};

        if (filter.type) {
          await this.filterResult(filter.type, filter.id, filter.lapNo);
        }
      }
      this.isArrowFilter = true;
    },
    toggleWatts(isTrue) {
      this.isWatt = isTrue;
      this.reloadTable += 1;
    },

    async setSelectedColumns() {
      if (
        IsSeriesPointAndTimeBasedEvents(this.event) &&
        this.isSeriesEvent &&
        !["individual", "master", "young"].includes(this.activeItem.type)
      ) {
        this.selectedColumns = this.selectedColumnsCopy.filter(
          (x) => x !== "finishedTime"
        );
      } else {
        this.selectedColumns = this.selectedColumnsCopy.filter(
          (x) => x !== "points"
        );
      }
    },


    async filterResult(filterType, key, lapNumber = 1, isLoading = true) {
      this.noResultsDataMessage = "Loading Data....";
      this.loading = isLoading;

      // set active item
      this.activeItem =
        [...this.filterItems, ...this.items, ...this.currentStageGates].find(
          (i) => i.type === filterType && i.id === key && i.lapNo === lapNumber
        ) || this.activeItemDefault;



      await this.setSelectedColumns();

      // this.result = this.resultBackup;
      this.result = [];
      this.showTeamsData = false;

      if (filterType === "teams") {
        this.showTeamsData = true;

        if (this.isStages) {
          await this.$store.dispatch("event/getTeamResults", {
            eventId: this.event.serverEventId,
            dayId: this.selectedDayId,
            isLive: this.isLive,
            isStages: this.isStages,
            isSeriesEvent: this.isSeriesEvent,
            categoryId: this.activeFilters?.categoryId || null,
          });
        } else {
          await this.$store.dispatch("event/getOverallTeamResults", {
            eventId: this.event.serverEventId,
            isLive: this.isLive,
            isStages: this.isStages,
            isSeriesEvent: this.isSeriesEvent,
            categoryId: this.activeFilters?.categoryId || null,
          });
        }
      } else {

        const filters = {
          eventId: this.event.serverEventId,
          filterType,
          key,
          isStages: this.isStages ? 1 : 0,
          isSeriesEvent: this.isSeriesEvent ? 1 : 0,
          currentDayId: this.selectedDayId,
          eventDatetimeEpoc: this.event?.datetime_epoc || null,
          isLive: this.isLive,
          lapNo: this.activeItem.lapNo || 1,
          categoryId: this.activeFilters?.categoryId || null,
          gateType: this.activeItem.gateType || 0,
          isAdminRequest: false,
          EventFormatType: this.event.EventFormatType,
          pp: this.$route.path.split("/")[1] == "pp",
          isUCI: this.isUCIEvent,
          isEleminationRace: this.isEleminationRaceBoolean || false
        };
        const resultData = await this.getEventResult(filters);
        this.result = resultData?.data?.resultData || [];
        this.totalEventPlayers = resultData?.data?.totalPlayers
        this.reloadTable += 1;
      }

      // apply filters
      for (const filter of Object.entries(this.activeFilters)) {
        this.result = this.result.filter((e) => e[filter[0]] === filter[1]);
      }


      this.noResultsDataMessage = !this.result.length ? "No Data is Available" : this.noResultsDataMessage;
      this.reloadTable += 1;
      this.loading = false;
    },

    gateMap(e, type, order) {
      let filterOrder = this.filterIdex(type === "overallgate") + order;
      return {
        id: e.GateId,
        order: filterOrder,
        label: e.GateName,
        type: type,
        icon: "",
        lapNo: 1,
        gateType: e.GateType,
      };
    },

    async setCurrentStageGates() {
      const duplicateGates = [];
      if (this.isEleminationRaceBoolean) { //computed property
        for (let i = 1; i <= this.stages[this.currentStage].NoOfLaps; i++) {
          this.filterItems.push({
            id: "daylap",
            order: 0,
            label: "Lap - " + i,
            type: "daylap",
            icon: "",
            lapNo: i,
          })
        }
      } else {
        if (this.stages[this.currentStage]?.ListOfGates?.length) {
          this.stages[this.currentStage].ListOfGates.map((gate) => {
            const gates = { ...gate, NoOfIterations: gate.NoOfIterations || 1 }; //if any gate doesnt have NoOfIterations it will set it as 1
            for (let i = 1; i <= gates.NoOfIterations; i++) {
              duplicateGates.push({
                id: gate.GateId,
                order: gate.GateOrder,
                label: gate.GateName + (i === 1 ? "" : " - Lap " + i),
                type: "gate",
                icon: "jersey-green.png",
                lapNo: i,
                gateType: gate.GateType || 0,
              });
            }
          });
        }
      }

      this.currentStageGates = (duplicateGates.length ? duplicateGates : []).sort((a, b) => a.order - b.order);
    },

    async addGates(gates) {
      const _overallgates = (gates.overallGates || []).filter(
        (e) => e.Overall === true
      );
      this.totalOverGates = _overallgates?.length;
      this.overAllGates = _overallgates
        .sort((a, b) => a.GateOrder - b.GateOrder)
        .map((e, orderIndex) => this.gateMap(e, "overallgate", orderIndex));

      this.stages = gates.days;
      this.isSeriesEvent = this.stages?.length > 1;
      this.isStages = this.stages?.length === 1;

      await this.setFilters();
    },

    async loadResult() {
      this.loading = true;
      const { eventId } = this.$route.query;
      this.topic = `eventId/${eventId}`;

      // is points based event
      if (!IsSinglePointBasedEvents(this.event)) {
        this.filterItems = [...this.filterItems, ...this.teamsLeaderboards];
        if (this.isEleminationRaceBoolean) {
          this.filterItems = this.filterItems.filter(item => item.id != "teams")
        }
      }

      this.filterResult(
        this.isOverallPointsGate?.type || "individual",
        this.isOverallPointsGate?.id || "individual"
      );
    },

    async getSelectedColumns() {
      const { eventId } = this.$route.query;
      const { data } = await this.$store.dispatch("event/getSelectedColumns", {
        eventId: eventId,
      });
      this.selectedColumns = data || [];
      this.selectedColumnsCopy = data || [];
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
