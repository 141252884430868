<template>
  <div>
    <header class="header-wrapper clearfix d-flex">

      <nav :class="`mobile-menu ${menuOpen ? 'open' : 'closed'}`">
          <a href="https://mywhoosh.com" class="logo">
              <img class="black-logo" src="/assets/images/my-whoosh-logo-black-v2.png" alt="MyWhoosh Logo">
              <img class="white-logo" src="/assets/images/my-whoosh-logo-white-v3.png" alt="MyWhoosh Logo">
          </a>
          <div class="inner-mobile-menu-wrap">
              <div class="site-nav-links">
                  <a class="page-hyper-link" :href="`https://mywhoosh.com/${makeQueryString}`">Home</a>
                  <a class="page-hyper-link" :href="`https://mywhoosh.com/events/${makeQueryString}`">Events</a>
                  <a class="page-hyper-link" :href="`https://mywhoosh.com/routes/${makeQueryString}`">Routes</a>
                  <a class="page-hyper-link" :href="`https://mywhoosh.com/blog/${makeQueryString}`">Blog</a>
                  <a class="page-hyper-link" :href="`https://results.mywhoosh.com/${makeQueryString}`">Results</a>
                  <a class="page-hyper-link" :href="`https://mywhoosh.com/docs/${makeQueryString}`">Support</a>

                  <a class="page-hyper-link" :href="`https://uci.mywhoosh.com/${makeQueryString}`">2024 UCI CEWC</a>

                  <a class="page-hyper-link" :href="`https://store.mywhoosh.com/${makeQueryString}`">Shop</a>

                  <a class="page-hyper-link" :href="`https://www.mywhoosh.com/podcast/${makeQueryString}`">Podcast</a>

                  <a class="page-hyper-link" :href="`https://mywhoosh.com/getting-started-mywhoosh-cycling-app/${makeQueryString}`">Download App</a>
                  <a class="page-hyper-link" :href="`https://mywhoosh.com/how-it-works/${makeQueryString}`">Get Started</a>
                  <a class="page-hyper-link" :href="`https://mywhoosh.com/about/${makeQueryString}`">About Us</a>
                  <span v-if="loggedInPlayerData">
                    <a class="page-hyper-link" href="javascript:void(0)">{{ getUserName }}</a>
                  </span>
                  <span v-else>
                    <a class="page-hyper-link" href="https://event.mywhoosh.com/auth/login">LOGIN</a>
                    <a class="page-hyper-link" href="https://event.mywhoosh.com/auth/register">SIGNUP</a>
                  </span>
              </div>
          </div>
      </nav>

      <a href="#" title="menu" :class="`mobile-menu-trigger ${menuOpen ? 'open' : 'closed'}`" @click="menuToggle(menuOpen)">
          <span></span>
          <span></span>
          <span></span>
      </a>
 

      <a href="https://mywhoosh.com" class="mobile-logo">
          <img class="black-logo" src="/assets/images/my-whoosh-logo-black-v2.png" alt="MyWhoosh Logo">
          <img class="white-logo" src="/assets/images/my-whoosh-logo-white-v3.png" alt="MyWhoosh Logo">
      </a>  
            
      <div class="max-width-wrap">
          <nav class="header-nav clearfix">
              <div class="left-menu-items-wrap menu-items-wrap clearfix">

                <a class="page-hyper-link" :href="`https://mywhoosh.com/events/${makeQueryString}`">Events</a>
                  <a class="page-hyper-link" :href="`https://mywhoosh.com/routes/${makeQueryString}`">Routes</a>
                  <a class="page-hyper-link" :href="`https://mywhoosh.com/blog/${makeQueryString}`">Blog</a>
                  <a class="page-hyper-link" :href="`https://results.mywhoosh.com/${makeQueryString}`">Results</a>
                  <a class="page-hyper-link" :href="`https://mywhoosh.com/docs/${makeQueryString}`">Support</a>
                
                  <a class="page-hyper-link" :href="`https://uci.mywhoosh.com/${makeQueryString}`">2024 UCI CEWC</a>
                  <a class="page-hyper-link" :href="`https://store.mywhoosh.com/${makeQueryString}`">Shop</a>

              </div>
                <a href="https://mywhoosh.com" class="desktop-logo">
                  <img class="black-logo" src="/assets/images/my-whoosh-logo-black-v2.png" alt="MyWhoosh Logo">
                  <img class="white-logo" src="/assets/images/my-whoosh-logo-white-v3.png" alt="MyWhoosh Logo">
                </a>
              <div class="right-menu-items-wrap menu-items-wrap clearfix">

               
                <a class="page-hyper-link" :href="`https://www.mywhoosh.com/podcast/${makeQueryString}`">Podcast</a>
                  <a class="page-hyper-link" :href="`https://mywhoosh.com/getting-started-mywhoosh-cycling-app/${makeQueryString}`">Download App</a>
                  <a class="page-hyper-link" :href="`https://mywhoosh.com/how-it-works/${makeQueryString}`">Get Started</a>
                  <a class="page-hyper-link" :href="`https://mywhoosh.com/about/${makeQueryString}`">About Us</a>

                  <a class="dropdown icon-name" v-if="loggedInPlayerData">
                    <button class="btn dropdown-toggle profile-icon-letter" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      <span class="p-name-letter rounded-circle">{{ getUserName.substring(0, 1) }}</span>
                    </button>
                    <ul class="dropdown-menu">
                      <li><a class="page-hyper-link" href="https://event.mywhoosh.com/user/activities#profile"  >View Profile</a></li>
                      <li><a class="page-hyper-link"  href="https://event.mywhoosh.com/user/activities#activities" >Activities</a></li>
                     </ul>
                  </a>
            
                  <span v-else>
                    <a href="https://event.mywhoosh.com/auth/login" class="page-hyper-link">LOGIN</a>
                    <a href="https://event.mywhoosh.com/auth/register" class="page-hyper-link">SIGNUP</a>
                  </span>
              </div>
          </nav>
      </div>
    </header>
  </div>
</template>

<style scoped>
button.profile-icon-letter {
  background: #f1aa00;
  width: 62px;
  height: 62px;
}

a:hover {
  text-decoration: none;
  color: transparent;
}

header.header-wrapper nav.header-nav .right-menu-items-wrap {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.icon-name {
  display: inline-block;
  width: auto;
}

.icon-name button {
  margin-left: 15px;
}

.profile-icon-letter {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  width: 62px;
}

.p-name-letter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  color: #282828;
  text-align: center;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  text-transform: uppercase;
}

.icon-name .dropdown-toggle::after {
  display: none !important;
}

.icon-name ul {
  padding: 12px 23px !important;
  border-radius: 1.25rem;
  margin-top: 17px !important;
  width: max-content;
}

.icon-name ul li {
  padding: 0 0 1.563px 0 !important;
}

.icon-name ul li a.page-hyper-link {
  margin: 0px auto !important;
  color: #282828 !important;
  font-family: Inter !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 31.5px !important;

  text-transform: uppercase !important;
  letter-spacing: initial !important;
}


nav.header-nav {
  background: #ffffff;
}

header.header-wrapper {

  padding: 37px 0;
  max-height: 100px;

}

@media (min-width: 1500px) {
  header.header-wrapper {
    padding: 0;
  }
}

header.header-wrapper .max-width-wrap {
  width: 100%;
  max-width: 1750px;
  margin: 0 auto;
}

 
header.header-wrapper .mobile-menu .closed {
  display: none;
}


header.header-wrapper .mobile-menu {
  -webkit-transition: 0.4s ease-in-out;
  -moz-transition: 0.4s ease-in-out;
  -o-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  position: relative;
  width: 100%;
   height: 100%;
  max-width: 100%;
  background-color: #ffffff;
  z-index: 9500;
  text-align: center;
  left: -100vw;
  top: 0;
  padding: 37px 0 0;
}




@media (min-width: 1500px) {
  header.header-wrapper .mobile-menu {
    display: none;
  }
}


header.header-wrapper .mobile-menu a.logo {
  -webkit-transition: 0.4s ease-in-out;
  -moz-transition: 0.4s ease-in-out;
  -o-transition: 0.4s ease-in-out;
  transition: 0.4s ease-in-out;
  transition-delay: 0.3s;
  display: block;
  width: 98px;
  margin: 0 auto;
  text-decoration: none;
  opacity: 0;
}

header.header-wrapper .mobile-menu a.logo img {
  width: 100%;
  height: auto;
}

header.header-wrapper .mobile-menu a.logo img.black-logo {
  display: block;
}

header.header-wrapper .mobile-menu a.logo img.white-logo {
  display: none;
}

header.header-wrapper .mobile-menu a.logo:hover {
  text-decoration: none;
}

header.header-wrapper .mobile-menu .inner-mobile-menu-wrap {
  padding: 20px 24px 0;
  text-align: left;
}

@media (min-width: 768px) {
  header.header-wrapper .mobile-menu .inner-mobile-menu-wrap {
    padding: 40px 44px 0;
  }
}

 



header.header-wrapper .mobile-menu .inner-mobile-menu-wrap .site-nav-links a.page-hyper-link {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  color: #1d1e1f;
  font-size: 14px;
  line-height: 56px;
  letter-spacing: 0.15em;
  margin: 0;
  display: block;
  width: 100%;
  text-transform: uppercase;
  text-decoration: none;
}

header.header-wrapper .mobile-menu .inner-mobile-menu-wrap .site-nav-links a.page-hyper-link:hover {
  text-decoration: underline;
}

header.header-wrapper .mobile-menu.open {
  left: 0;
}

header.header-wrapper .mobile-menu.open a.logo {
  opacity: 1;
}

header.header-wrapper a.mobile-menu-trigger {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  width: 20px;
  height: 14px;
  position: absolute;
  top: 50px;
  left: 20px;
  z-index: 9800;
  display: block;
  color: #222222;
}

@media (min-width: 768px) {
  header.header-wrapper a.mobile-menu-trigger {
    left: 40px;
  }
}

@media (min-width: 1500px) {
  header.header-wrapper a.mobile-menu-trigger {
    display: none;
  }
}

header.header-wrapper a.mobile-menu-trigger span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #222222;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

header.header-wrapper a.mobile-menu-trigger span:nth-child(1) {
  top: 0px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

header.header-wrapper a.mobile-menu-trigger span:nth-child(2) {
  top: 6px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

header.header-wrapper a.mobile-menu-trigger span:nth-child(3) {
  top: 12px;
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
}

header.header-wrapper a.mobile-menu-trigger:hover {
  cursor: pointer;
}

header.header-wrapper a.mobile-menu-trigger:hover span:hover {
  cursor: pointer;
}

header.header-wrapper a.mobile-menu-trigger.open span:nth-child(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 0px;
}

header.header-wrapper a.mobile-menu-trigger.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

header.header-wrapper a.mobile-menu-trigger.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  top: 14px;
}

header.header-wrapper a.mobile-logo {
  display: block;
  width: 98px;
  margin: 0 auto;
  max-height: 100px;
  text-decoration: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%); /* Centers it perfectly */
}

@media (min-width: 1500px) {
  header.header-wrapper a.mobile-logo {
    width: 135px;
    display: none;
  }
}

header.header-wrapper a.mobile-logo img {
  width: 100%;
  height: auto;
}

header.header-wrapper a.mobile-logo img.black-logo {
  display: block;
}

header.header-wrapper a.mobile-logo img.white-logo {
  display: none;
}

header.header-wrapper a.mobile-logo:hover {
  text-decoration: none;
}

header.header-wrapper nav.header-nav {
  display: none;
}

@media (min-width: 1500px) {
  header.header-wrapper nav.header-nav {

    height: 96px;
    display: block;
    width: 100%;
    margin: 0 auto;
    padding: 24px 4% 0;
  }
}

header.header-wrapper nav.header-nav .menu-items-wrap a.page-hyper-link {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  color: #121212;
  font-size: 12px;
  line-height: 56px;
  letter-spacing: 0.15em;
  margin: 0;
  display: inline-block;
  width: auto;
  text-transform: uppercase;
  text-decoration: none;
}

header.header-wrapper nav.header-nav .menu-items-wrap a.page-hyper-link:hover {
  text-decoration: underline;
}

header.header-wrapper nav.header-nav .left-menu-items-wrap {
  float: left;
}

header.header-wrapper nav.header-nav .left-menu-items-wrap a.page-hyper-link {
  margin: 0 25px 0 0;
}

header.header-wrapper nav.header-nav .right-menu-items-wrap {
  float: right;
}

header.header-wrapper nav.header-nav .right-menu-items-wrap a.page-hyper-link {
  margin: 0 0 0 25px;
}

header.header-wrapper nav.header-nav a.desktop-logo {
  position: absolute;
  display: block;
  width: 135px;
  text-decoration: none;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
}

header.header-wrapper nav.header-nav a.desktop-logo img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

header.header-wrapper nav.header-nav a.desktop-logo img.black-logo {
  display: block;
}

header.header-wrapper nav.header-nav a.desktop-logo img.white-logo {
  display: none;
}

header.header-wrapper nav.header-nav a.desktop-logo:hover {
  text-decoration: none;
}

header.header-wrapper * {
  box-sizing: border-box;
}

header.header-wrapper.header-in-white a.mobile-menu-trigger {
  color: #ffffff;
}

header.header-wrapper.header-in-white a.mobile-menu-trigger span {
  background: #ffffff;
}

header.header-wrapper.header-in-white a.mobile-menu-trigger.open span {
  background: #000000;
}

header.header-wrapper.header-in-white a.mobile-logo img.black-logo {
  display: none;
}

header.header-wrapper.header-in-white a.mobile-logo img.white-logo {
  display: block;
}

header.header-wrapper.header-in-white nav.header-nav .menu-items-wrap a.page-hyper-link {
  color: #ffffff;
}

header.header-wrapper.header-in-white nav.header-nav a.desktop-logo img.black-logo {
  display: none;
}

header.header-wrapper.header-in-white nav.header-nav a.desktop-logo img.white-logo {
  display: block;
}
</style>

<script>

import { mapGetters } from "vuex";
import { getRedirectionUrl } from '../events/event.helpers.ts';


export default {
  name: "HeaderComponent",
  data() {
    return {
      wpURL: process.env.VUE_APP_WEB_URL,
      menuOpen: false,
      redirectUrl: getRedirectionUrl
    };
  },

  computed: {

    ...mapGetters("player", ["loggedInPlayerData"]),


    getUserAvatar() {
      const def = this.avatar.split("/");
      if (def[def.length - 1] === "avatar.png") {
        return this.assetsUrl() + "/images/" + this.avatar;
      }
      return this.makeImage(this.avatar, 350, 350, true);
    },

    getUserName() {
      return this.loggedInPlayerData?.firstName + " " + this.loggedInPlayerData?.lastName

    },

    makeQueryString() {
      return `?login=${this.loggedInPlayerData ? true : false}${this.loggedInPlayerData ? `&profile=${this.getUserName}` : ""}`
    }
  },

  mounted() {
    const bootstrapJs = document.createElement("script");
    bootstrapJs.setAttribute(
      "src",
      "/assets/js/bootstrap.bundle.min.js"
    );
    document.head.appendChild(bootstrapJs);
    // check if player is logged in
    this.$store.dispatch("auth/loggedIn");
  },

  methods: {

    wpWebUrl() {
      return 'https://mywhoosh.com';
    },

    menuToggle(isOpen = false) {
      this.menuOpen = !isOpen;
    },

    makePageLink(slug = null) {

      return `https://mywhoosh.com/${slug}`


      // const currentHost = window.location.host
      // if ((currentHost.includes('my-whoosh') || currentHost.includes('localhost')) && slug == 'results') {
      //   return window.location.origin
      // }
      // if (currentHost.includes('mywhoosh') && slug != 'results') {
      //   return `https://mywhoosh.com/${slug}`
      // } 
      // else if(currentHost.includes('mywhoosh') && slug == 'results') {
      //   return window.location.origin
      // }
      // return `https://prod-externalwp.mywhoosh.com/${slug}/${this.makeQueryString}`;
    },

    dropdownLinksBuilding(link) {
      console.log(link)
    },

    logout() {
      // this.$store.dispatch("auth/logout");
      document.cookie = 'whoosh_uuid' + '=; Max-Age=0'
      window.location.reload()
      // this.$router.push({ name: "EventListResult" });
    },
  },
};
</script>